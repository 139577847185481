import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import PaidTickerInfoGet from "@/store/analysis/paid-ticket-info/get";
import PaidTicketUseGet from "@/store/analysis/paid-ticket-use/get";
import PaidTicketContinueGet from "@/store/analysis/paid-ticket-continue/get";
import Admin from "@/store/admin/admin";
import DigitalTicketChart from "@/views/analysis/DIgitalTicketChart.vue";
import { PaidTicketUseGetRequest } from "@/api/analysis/paid-ticket-use/request";
import { PaidTicketContinueGetRequest } from "@/api/analysis/paid-ticket-continue/request";
import { PaidTicketInfoGetItem } from "@/api/analysis/paid-ticket-info/response";

export interface PaidTicketInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  selectShop: { id: string; name: string } | null;
  selectTicket: { id: number; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    DigitalTicketChart
  }
})
export default class AnalysisPaidTicket extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "デジタルチケット";
  headingSub = "Digital ticket";
  breadCrumbs = [{ text: "デジタルチケット", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 },
    { label: "年別", value: 3 },
    { label: "時間別", value: 4 },
    { label: "曜日別", value: 5 },
    { label: "チケット別", value: 6 }
  ];

  tabItems = [{ title: "利用数" }, { title: "継続数" }];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedTicketData = false;
  isLoadedPaidTicketData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  paidTicketInputOptions: PaidTicketInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    selectShop: null,
    selectTicket: null
  };

  selectedTicket: PaidTicketInfoGetItem = {} as PaidTicketInfoGetItem;

  @Watch("activeTab")
  watchActiveTab(newTabIndex: number) {
    if (newTabIndex == 0) {
      this.viewTypes = [
        { label: "日別", value: 1 },
        { label: "月別", value: 2 },
        { label: "年別", value: 3 },
        { label: "時間別", value: 4 },
        { label: "曜日別", value: 5 },
        { label: "チケット別", value: 6 }
      ];
    } else {
      // NEW_DEV-2073 cyber start
      if (this.paidTicketInputOptions.viewType == 4 || this.paidTicketInputOptions.viewType == 5){
        this.paidTicketInputOptions.viewType = 1;
      }
      // NEW_DEV-2073 cyber end
      this.viewTypes = [
        { label: "日別", value: 1 },
        { label: "月別", value: 2 },
        { label: "年別", value: 3 },
        { label: "チケット別", value: 6 }
      ];
    }
  }

  @Watch("paidTicketInputOptions", { deep: true })
  paidTicketInputOptionsDidChange(inputOptions: PaidTicketInputOptions) {
    if (inputOptions.selectTicket == null) {
      this.selectedTicket = {} as PaidTicketInfoGetItem;
      return;
    }
    const selectedTicket = this.ticketItems.find(t => {
      return (
        (inputOptions.selectTicket != null
          ? inputOptions.selectTicket.id
          : null) == t.id
      );
    });
    if (selectedTicket) {
      this.selectedTicket = selectedTicket;
    }
  }

  @Watch("paidTicketInputOptions.selectShop")
  async selectShopDidChange(selectShop: { id: string; name: string } | null) {
    this.paidTicketInputOptions.selectTicket = null;
    if (selectShop) {
      await this.fetchTicketList();
    } else {
      await PaidTickerInfoGet.clearResponse();
    }
  }

  @Watch("paidTicketInputOptions.viewType")
  async viewTypeDidChange(viewType: number) {
    if (viewType == 6) {
      this.paidTicketInputOptions.selectTicket = null;
    }
  }

  /**
   * 総件数
   */
  get totalCount() {
    return this.activeTab === 0
      ? PaidTicketUseGet.getTotalCount
      : PaidTicketContinueGet.getTotalCount;
  }

  get paidTicketData() {
    return this.activeTab === 0
      ? PaidTicketUseGet.getData
      : PaidTicketContinueGet.getData;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.paidTicketInputOptions.startDate);
  }
  set startDateForView(date) {
    this.paidTicketInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.paidTicketInputOptions.endDate);
  }
  set endDateForView(date) {
    this.paidTicketInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get activeTab() {
    return this.$route.query.adt_tab ? Number(this.$route.query.adt_tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.adt_tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get ticketItems() {
    return PaidTickerInfoGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    this.watchActiveTab(this.activeTab);
    if (!Admin.isAdmin) {
      this.paidTicketInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchTicketList();
      this.paidTicketInputOptions.selectTicket = {
        id: PaidTickerInfoGet.getItems[0].id,
        name: PaidTickerInfoGet.getItems[0].title
      };
      this.selectedTicket = this.ticketItems[0];
      await this.fetchPaidTicket();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await PaidTickerInfoGet.clearResponse();
    await PaidTicketUseGet.clearResponse();
    await PaidTicketContinueGet.clearResponse();
  }

  async fetchTicketListAndPaidTicket() {
    await this.fetchPaidTicket();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * デジタルチケット情報の一覧を取得する処理
   */
  async fetchTicketList(): Promise<boolean> {
    this.isLoadedTicketData = false;
    await PaidTickerInfoGet.get({
      shopId:
        this.paidTicketInputOptions.selectShop != null
          ? Number(this.paidTicketInputOptions.selectShop.id)
          : null
    });
    if (!PaidTickerInfoGet.isSuccess) {
      await Flash.setErrorNow({
        message: PaidTickerInfoGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedTicketData = true;
    return PaidTickerInfoGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchPaidTicket(): Promise<boolean> {
    this.isLoadedPaidTicketData = false;
    await Flash.clear();
    const paidTicketGetRequest: PaidTicketUseGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      viewType: this.paidTicketInputOptions.viewType,
      searchShopId:
        this.paidTicketInputOptions.selectShop != null
          ? Number(this.paidTicketInputOptions.selectShop.id)
          : null
    };
    if (this.paidTicketInputOptions.viewType != 6) {
      paidTicketGetRequest.searchTicketId =
        this.paidTicketInputOptions.selectTicket != null
          ? this.paidTicketInputOptions.selectTicket.id
          : null;
    }

    let isSucsses = false;
    if (this.activeTab == 0) {
      await PaidTicketUseGet.get(paidTicketGetRequest);
      if (!PaidTicketUseGet.isSuccess) {
        await Flash.setErrorNow({
          message: PaidTicketUseGet.getMessage,
          showReloadButton: false
        } as ErrorAlert);
      }
      this.isLoadedPaidTicketData = true;
      isSucsses = PaidTicketUseGet.isSuccess;
    } else {
      // NEW_DEV-2073 cyber start
      if (paidTicketGetRequest.viewType == 4 || paidTicketGetRequest.viewType == 5) {
        paidTicketGetRequest.viewType = 1;
      }
      // NEW_DEV-2073 cyber end
      await PaidTicketContinueGet.get(
        paidTicketGetRequest as PaidTicketContinueGetRequest
      );
      if (!PaidTicketContinueGet.isSuccess) {
        await Flash.setErrorNow({
          message: PaidTicketContinueGet.getMessage,
          showReloadButton: false
        } as ErrorAlert);
      }
      this.isLoadedPaidTicketData = true;
      isSucsses = PaidTicketContinueGet.isSuccess;
    }
    return isSucsses;
  }
}
