import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PaidTicketContinueAPI from "@/api/analysis/paid-ticket-continue";
import { isSuccess } from "@/api/response";
import { PaidTicketContinueGetRequest } from "@/api/analysis/paid-ticket-continue/request";
import { PaidTicketContinueGetResponse } from "@/api/analysis/paid-ticket-continue/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/paid-ticket-continue/get";

/**
 *  デジタルチケット利用数取得API（/analysis-paid-ticket-use）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PaidTicketContinueGetRequest = {} as PaidTicketContinueGetRequest;
  getResponse: PaidTicketContinueGetResponse = {} as PaidTicketContinueGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: PaidTicketContinueGetRequest) {
    const getResponse = await PaidTicketContinueAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PaidTicketContinueGetResponse
    };
  }
}

export default getModule(Get);
