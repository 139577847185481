import { Component, Mixins, Prop } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import * as PaidTicketUserCSV from "@/api/analysis/paid-ticket-user-csv";
import { isSuccess } from "@/api/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { saveAs } from "file-saver";
import { PaidTicketInputOptions } from "@/models/analysis/digital-ticket";
import { PaidTicketUserCSVGetRequest } from "@/api/analysis/paid-ticket-user-csv/request";
import PaidTicketUseGet from "@/store/analysis/paid-ticket-use/get";
import PaidTicketContinueGet from "@/store/analysis/paid-ticket-continue/get";

@Component({
  components: {
    UlBarChart: UlBarChart
  }
})
export default class AnalysisPaidTicketChart extends Mixins(AnalysisBase) {
  @Prop() data!: AnalysisGraphData;
  @Prop() inputOptions!: PaidTicketInputOptions;
  @Prop() activeTab!: number;

  get paidTicketChartData() {
    return this.getBarChartData(this.data);
  }

  get paidTicketChartOption() {
    return this.getBarChartOptions(
      this.data,
      this.activeTab === 1,
      false,
      this.onClickChart
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async onClickChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard
    ) {
      return;
    }
    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchPaidTicketUserCSV(element._index);
    }
  }

  async fetchPaidTicketUserCSV(elementIndex: number) {
    let horizontalAxis = "";
    if (this.activeTab == 0) {
      horizontalAxis = PaidTicketUseGet.getData.labelsData[elementIndex];
    } else {
      horizontalAxis = PaidTicketContinueGet.getData.labelsData[elementIndex];
    }

    const paidTicketUserCSVGetRequest: PaidTicketUserCSVGetRequest = {
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null,
      graphType: this.activeTab + 1,
      horizontalAxis: horizontalAxis
    };

    if (this.inputOptions.viewType != 6) {
      paidTicketUserCSVGetRequest.searchTicketId =
        this.inputOptions.selectTicket != null
          ? this.inputOptions.selectTicket.id
          : null;
    }

    const paidTicketUserCSVGetResponse = await PaidTicketUserCSV.get(
      paidTicketUserCSVGetRequest
    );

    if (
      paidTicketUserCSVGetResponse &&
      paidTicketUserCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: paidTicketUserCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.saveCSV(
      paidTicketUserCSVGetResponse.results.csvString,
      paidTicketUserCSVGetResponse.results.fileName
    );
  }
}
