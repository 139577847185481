import service from "@/api/service";
import { PaidTicketContinueGetRequest } from "@/api/analysis/paid-ticket-continue/request";
import { PaidTicketContinueGetResponse } from "@/api/analysis/paid-ticket-continue/response";

/**
 * サブスクリプションチケット継続数取得APIをコールします。
 *
 * @param getRequest サブスクリプションチケット継続数取得APIのリクエストボディ
 * @return PaidTicketContinueGetResponse
 */
export async function get(getRequest: PaidTicketContinueGetRequest) {
  const response = await service.post(
    "/analysis-paid-ticket-continue",
    getRequest
  );
  return response.data as PaidTicketContinueGetResponse;
}
