import service from "@/api/service";
import { PaidTicketUseGetResponse } from "@/api/analysis/paid-ticket-use/response";
import { PaidTicketUseGetRequest } from "@/api/analysis/paid-ticket-use/request";

/**
 * デジタルチケット利用数取得APIをコールします。
 *
 * @param getRequest デジタルチケット利用数取得APIのリクエストボディ
 * @return PaidTicketUseGetResponse
 */
export async function get(getRequest: PaidTicketUseGetRequest) {
  const response = await service.post("/analysis-paid-ticket-use", getRequest);
  return response.data as PaidTicketUseGetResponse;
}
