import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { Bar, mixins } from "vue-chartjs";
import Chart from "chart.js";

@Component
export default class UlBarChart extends Mixins(Bar, mixins.reactiveProp) {
  @Prop() chartData!: Chart.ChartData;
  @Prop() chartOptions!: Chart.ChartOptions;

  @Emit()
  public chartRendered() {
    return this.$data._chart;
  }

  public async mounted() {
    await this.renderChart(this.chartData, this.chartOptions);
    this.chartRendered();
  }
}
